<template>
   <div
      id="questionMaker"
      class="light-bg highlight-border"
   >
      <h3>
         <button
            v-on:click="previousViewCallback"
            class="small-button ultra-light-bg"
         >
            <FontAwesomeIcon
               :icon="leftIcon"
            />
            Back
         </button>
         New Question
      </h3>
      <div
         id="editorWrapper"
         class="border-top site-bg"
      >
         <div class="flex-grid pad-bottom-1">
            <div class="col-xs col-label muted">
               Text
            </div>
            <div class="col-md">
               <form>
                  <textarea v-model="questionText">
                  </textarea>
               </form>
            </div>
            <div class="col-xs text-right">
               <div id="textControlButtons">
                  <button
                     v-on:click="handleCancelEdit"
                     class="ultra-light-bg control-button"
                  >
                     Cancel
                  </button>
               </div>
            </div>
         </div><!-- end text row -->
         <div class="flex-grid">
            <div class="col-xs col-label muted">
               Source
            </div>
            <div class="col-md">
               <form>
                  <textarea
                     v-model="questionSource"
                     class="small"
                  >
                  </textarea>
               </form>
            </div>
            <div class="col-xs text-right">
               <!-- space filler -->
            </div>
         </div><!-- end source row -->
         <hr/>
         <OptionMaker v-bind:updateOptionsCallback="updateOptionsCallback"/>
         <hr/>
         <div class="flex-grid no-pad">
            <div class="col-xs col-label muted">
               Actions
            </div>
            <div class="col-md text-right pad-right-1">
               <ControlBar
                  v-bind:buttons="controlButtons"
                  v-bind:position="'right'"
               />
            </div>
         </div>
      </div><!-- editorWrapper -->
   </div>
</template>

<script>
   // Import functions
   //import { inject } from 'vue'
   import { postQuestion } from '@/api/approvals.js'
   import { makeToast } from '@/api/toast.js'
   // Import UI components
   import { faHandPointLeft } from '@fortawesome/free-solid-svg-icons'
   import OptionMaker from '@/views/questions/OptionMaker.vue'
   import ControlBar from '@/components/ControlBar.vue';
   // Export component
   export default {
      name: 'QuestionMaker',
      props: {
         question: {
            type: Object,
            default: () => {}
         },
         previousViewCallback: {
            type: Function,
            default: () => {}
         },
         axios: {
            type: Object,
            default: () => {}
         },
      },
      data: () => {
         return {
            leftIcon: faHandPointLeft,
            questionText: '',
            questionSource: '',
            format: 'text',
            options: null,
         }
      },
      computed: {
         controlButtons: function() {
            return [
               {
                  text: 'Save Question',
                  icon: 'plusSquare',
                  variant: 'highlight',
                  onClickCallback: (e) => {
                     this.handleSaveQuestion(e)
                  },
               },
            ]
         },
      },
      components: {
         'OptionMaker': OptionMaker,
         'ControlBar': ControlBar,
      },
      methods: {
         updateOptionsCallback(options) {
            this.options = options
         },
         handleCancelEdit(e) {
            e.preventDefault()
            // Exit this view
            this.previousViewCallback()
         },
         handleSaveQuestion(e) {
            e.preventDefault()
            const params = {
               data: {
                  text: this.questionText,
                  source: this.questionSource,
                  format: this.format,
                  options: this.options
               },
               axios: this.axios,
               callback: (res) => {
                  if(res.status === 201) {
                     makeToast('Question created', 'success')
                     this.previousViewCallback()
                  } else if(res.response.status >= 400) {
                     // Make readable error message
                     let msg = `${res.response.data.data.public}:`
                     if(res.response.data.data.details) {
                        res.response.data.data.details.forEach(val => {
                           msg += ` ${val}`
                        })
                     }
                     makeToast(msg, 'danger')
                  }
               },
            }
            // Make request to create question
            postQuestion(params)
         },
      },
   }
</script>

<style scoped>
   form {
      display: inline-block;
      margin: 0;
      width: 100%;
   }
   textarea {
      width: calc(90% - 2.4em); /* compensate for padding */
      height: 5.5em;
      resize: none;
      border-radius: 3px;
      padding: 1em;
   }
   textarea.small {
      height: 2.5em;
   }
   #editorWrapper {
      text-align: center;
      padding: 2em 1em 1em;
   }
</style>
