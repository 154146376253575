<template>
   <div id="approvalsManager">
      <div id="wrapper">
         <Header/>
         <Navigation v-bind:options="navigationOptions"/>
         <div class="flex-grid">
            <div class="col-lg">
               <QuestionList
                  v-if="!showMaker && !showEditor"
                  v-bind:title="listTitle"
                  v-bind:emptyListMessage="emptyListMessage"
                  v-bind:questions="questions"
                  v-bind:isLoading="isLoading"
                  v-bind:questionClickCallback="handleQuestionClick"
               />
               <Pagination
                  v-if="!showMaker && !showEditor && Object.keys(questions).length > 0"
                  v-bind:showBorder="false"
                  v-bind:itemCount="Object.keys(questions).length"
                  v-bind:totalItems="unapprovedQuestionCount"
                  v-bind:pageChangeCallback="refreshQuestions"
               />
               <hr v-if="!showMaker && !showEditor"/>
               <div
                  v-if="!showMaker && !showEditor"
                  class="flex-grid no-pad"
               >
                  <div class="col-xs col-label muted">
                     Actions
                  </div>
                  <div class="col-md text-right pad-right-1">
                     <ControlBar
                        v-bind:buttons="controlButtons"
                        v-bind:position="'right'"
                     />
                  </div>
               </div>
               <!-- For creating a new question -->
               <QuestionMaker
                  v-if="showMaker && !showEditor"
                  v-bind:previousViewCallback="refreshQuestionsCallback"
                  v-bind:axios="axios"
               />
               <!-- For editing an unapproved question -->
               <QuestionEditor
                  v-if="!showMaker && showEditor"
                  v-bind:previousViewCallback="refreshQuestionsCallback"
                  v-bind:updateQuestionCallback="updateQuestionCallback"
                  v-bind:question="currentQuestion"
                  v-bind:currentTag="currentTag"
                  v-bind:tags="tags"
                  v-bind:questionIsUnapproved="true"
               />
            </div>
         </div>
         <CopyrightFooter/>
      </div> <!-- wrapper -->
   </div>
</template>

<script>
   // Import functions
   import { inject } from 'vue'
   import { getUnapprovedQuestions } from '@/api/approvals.js'
   import { getTags } from '@/api/tags.js'
   import { getNavigationOptions } from '@/api/navigation.js'
   // UI components
   import Header from '@/components/Header.vue'
   import Navigation from '@/components/Navigation.vue'
   import Pagination from '@/components/Pagination.vue'
   import CopyrightFooter from '@/components/CopyrightFooter.vue'
   import QuestionList from '@/components/QuestionList.vue'
   import ControlBar from '@/components/ControlBar.vue'
   import QuestionMaker from '@/views/questions/QuestionMaker.vue'
   import QuestionEditor from '@/views/questions/QuestionEditor.vue'
   // Export component
   export default {
      name: 'ApprovalsManager',
      props: {
      },
      data: () => {
         return {
            tags: [],
            questions: {},
            currentQuestion: null,
            currentTag: {
               id: -1,
               name: 'Unapproved'
            },
            unapprovedQuestionCount: 10, // default value
            showMaker: false,
            showEditor: false,
            isLoading: false,
            listTitle: 'Unapproved Questions',
            emptyListMessage: 'No questions found',
         }
      },
      computed: {
         navigationOptions: function() {
            return getNavigationOptions(this.router)
         },
         controlButtons: function() {
            return [
               {
                  text: 'New Question',
                  icon: 'plusSquare',
                  variant: 'standard',
                  onClickCallback: () => {
                     // Show new question editor
                     this.showMaker = true
                     // Reset data
                     this.currentQuestion = null
                  }
               },
            ]
         },
      },
      components: {
         'Header': Header,
         'Navigation': Navigation, // horizontal list for inter-view navigation
         'QuestionList': QuestionList,
         'Pagination': Pagination,
         'ControlBar': ControlBar,
         'CopyrightFooter': CopyrightFooter,
         'QuestionMaker': QuestionMaker,
         'QuestionEditor': QuestionEditor,
      },
      methods: {
         updateQuestionCallback(question) {
            this.currentQuestion = question
         },
         refreshQuestionsCallback() {
            this.refreshQuestions(1, 10)
            this.previousViewCallback()
         },
         previousViewCallback() {
            this.showMaker = false
            this.showEditor = false
            // Reset current question
            this.currentQuestion = null
         },
         handleQuestionClick(id) {
            this.currentQuestion = this.questions.find(val => val.id === id)
            this.showEditor = true
         },
         refreshQuestions(page, pageSize) {
            // console.log('refreshQuestions', page, pageSize)
            const params = {
               axios: this.axios,
               page: page,
               pageSize: pageSize,
               callback: (resData) => {
                  // Store questions as data
                  this.questions = resData.data.data
                  this.unapprovedQuestionCount = resData.data.pagination.total_records
               }
            }
            // Request paginated unapproved questions from API
            getUnapprovedQuestions(params)
         },
      },
      setup() {
         // Inject axios and provide as prop to component for use with API calls
         const $axios = inject('$axios')
         // Same with router
         const $router = inject('$router')
         return {
            axios: $axios,
            router: $router
         }
      },
      beforeCreate() {
         // Get tags
         let params = {
            callback: (resData) => {
               // Process data for generic ListNav
               const filteredTags = resData.data.map(val => {
                  return {
                     id: val.tag_id,
                     name: val.name,
                     count: val.number_of_questions
                  }
               })
               // Store tag list
               this.tags = filteredTags
            }
         }
         // Make request for tags
         getTags(params)
         // Get unapproved questions
         const defaultPage = 1;
         const defaultSize = 10;
         params = {
            axios: this.axios,
            page: defaultPage,
            pageSize: defaultSize,
            callback: (resData) => {
               // Store questions as data
               this.questions = resData.data.data
               this.unapprovedQuestionCount = resData.data.pagination.total_records
            }
         }
         // Request paginated unapproved questions from API
         getUnapprovedQuestions(params)
      },
   }
</script>

<style scoped>
   #approvalsManager {
      text-align: left;
      margin: 1em 0 0;
   }
   #wrapper {
      max-width: 1500px;
      margin: 0 auto;
      position: relative;
   }
</style>
